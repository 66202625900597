export const generateToken = async (transactionId) => {
    const response = await fetch('/createLinkToken', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transactionId })
    });
    const data = await response.json();    
    return data.link_token;
};