import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCurrentStep } from '../../../../redux/form/formSlice';
import PlaidBankLink from '../../../PlaidBankLink';

const IntroStep = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formData = useSelector(state => state.form);

    const goToNextStep = () => {
        dispatch(updateCurrentStep(1)); // Update to the correct next step number
        navigate('/step-1'); // Update to the correct next step path        
    };

    const canProceed = false;

    return (
        <Container>
            <Box component="form" noValidate sx={{ mt: 1 }}>
                <Box mt={2}>
                    <Typography variant="h6" color="textPrimary" mt={2}>
                        <b>Hey there! Quick heads up:</b>
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        We're teaming up with Plaid to make your application a breeze! 🚀 <br />
                        Plaid helps us securely and swiftly connect to your bank, so we can auto-fill your details from your bank info to your personal contact info. <br />
                        It's all about making things faster and safer for you. 
                        We just currently don't integrate with Chase Bank yet. 
                        So if you bank with Chase, select the manual route below.
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={3} mb={4}>
                        {formData.linkToken && (
                            <PlaidBankLink onCompletion={goToNextStep} />
                        )}
                        <Button variant="text" color="primary" onClick={goToNextStep} sx={{ mt: 2, typography: { fontSize: '0.9rem' } }}>
                            Prefer the manual route? Just click here to add your details. Quick and easy, though not Plaid-instant.
                        </Button>
                    </Box>
                </Box>
                {/* <Box mt={2}>
          <FormButton
            onClick={goToPreviousStep}
            text="Back">
          </FormButton>
          <FormButton
            onClick={goToNextStep}
            text="Next"
            disabled={!canProceed}>
          </FormButton>
        </Box> */}
            </Box>
        </Container>
    );
};

export default IntroStep;
