import React from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { hideSpinner, showSpinner } from '../redux/spinner/spinnerSlice';
import { setSuccessOpen, setErrorOpen, setSuccessMessage, setErrorMessage } from '../redux/snackbar/snackbarSlice';
import { updateField } from '../redux/form/formSlice';
import FormButton from './Buttons/FormButton';
import { useDispatch, useSelector } from 'react-redux';


const PlaidBankLink = ({ onCompletion }) => {
    const dispatch = useDispatch();
    const formData = useSelector(state => state.form);


    const startLoading = () => {
        dispatch(showSpinner());
    };

    const stopLoading = () => {
        dispatch(hideSpinner());
    };

    const onSuccess = React.useCallback(async (publicToken, metadata) => {    
        try {
            startLoading();
            await sendPublicToken(publicToken);
            await getContactInfoFromPlaid();
            await validateBankAccount();
            dispatch(setSuccessMessage("We have successfully connected with Plaid! Let's proceed! 🚀"));
            dispatch(setSuccessOpen(true));
        } catch (error) {
            dispatch(setErrorMessage("There was trouble connecting with Plaid. Let's proceed the old fashioned way."));
            dispatch(setErrorOpen(true));            
        } finally {
            onCompletion();
            stopLoading();
        }
    }, []);

    const sendPublicToken = async (publicToken) => {
        const response = await fetch('/exchangePlaidToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                publicToken,
                transactionId: formData.transactionId
            })
        });
    
        if (!response.ok) {
            throw new Error(`Failed to exchange public token for access token`);
        }
    };

    const getContactInfoFromPlaid = async () => {
        const response = await fetch(`/getContactInfoFromPlaid?transactionId=${formData.transactionId}`);
        
        if (!response.ok) {
            throw new Error(`Failed to get contact info from plaid`);
        }
    
        const data = await response.json();
        const { address = {}, email, phoneNumber, firstName, middleName, lastName } = data ?? {};
        const { city, country, postal_code, region, street } = address;        
        dispatch(updateField({ fieldName: 'city', fieldValue: city }));
        dispatch(updateField({ fieldName: 'country', fieldValue: country }));
        dispatch(updateField({ fieldName: 'zipCode', fieldValue: postal_code }));
        dispatch(updateField({ fieldName: 'state', fieldValue: region }));
        dispatch(updateField({ fieldName: 'address', fieldValue: street }));
        dispatch(updateField({ fieldName: 'email', fieldValue: email }));
        dispatch(updateField({ fieldName: 'phoneNumber', fieldValue: phoneNumber }));
        dispatch(updateField({ fieldName: 'firstName', fieldValue: firstName }));
        dispatch(updateField({ fieldName: 'middleName', fieldValue: middleName }));
        dispatch(updateField({ fieldName: 'lastName', fieldValue: lastName }));
    };

    const validateBankAccount = async () => {
        const response = await fetch(`/validateBankAccount?transactionId=${formData.transactionId}`);
        
        if (!response.ok) {
            throw new Error(`Could not validate bank account with plaid.`);
        }
    
        const data = await response.json();        
        dispatch(updateField({ fieldName: 'hasValidBankAccount', fieldValue: data.hasValidBankAccount }));
    };

    const config = {
        token: formData.linkToken,
        onSuccess
    };

    const { open, ready, exit } = usePlaidLink(config);

    function openPlaid() {
        exit(); // remove this
        open();
    }


    return (
        <FormButton onClick={openPlaid} disabled={!ready}
            text="Let's Get Started!">
        </FormButton>
    );
};

export default PlaidBankLink;