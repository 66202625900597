export const upsertTransaction = async (transactionId, merchantId, firstName, lastName, email, phone) => {
    const response = await fetch('/upsertTransaction', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            transactionId: transactionId,
            merchantId: merchantId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
        })
    });

    const data = await response.json();
    return data.transactionId;
}