import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: { successOpen: false, errorOpen: false, successMessage: '', errorMessage: '' },
  reducers: {
    setSuccessOpen: (state, action) => {
      state.successOpen = action.payload;
    },
    setErrorOpen: (state, action) => {
      state.errorOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setSuccessOpen, setErrorOpen, setSuccessMessage, setErrorMessage } = snackbarSlice.actions;

export default snackbarSlice.reducer;