import { configureStore } from '@reduxjs/toolkit';
import formReducer from './form/formSlice';
import spinnerReducer from './spinner/spinnerSlice';
import snackbarReducer from './snackbar/snackbarSlice';

const store = configureStore({
  reducer: {
    form: formReducer,
    spinner: spinnerReducer,
    snackbar: snackbarReducer,
  },
});

export default store;
