import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import NameStep from '../FormSteps/NameStep';
import HousingStep from '../FormSteps/HousingStep';
import ContactStep from '../FormSteps/ContactStep';
import { useSelector } from 'react-redux';
import LinearProgressBar from '../../LinearProgressBar';
import AddressStep from '../FormSteps/AddressStep';
import OccupationStep from '../FormSteps/OccupationStep';
import EmployerStep from '../FormSteps/EmployerStep';
import BankStep from '../FormSteps/BankStep/BankStep';
import CreditCardStep from '../FormSteps/CreditCardStep';
import ReferenceOneStep from '../FormSteps/ReferenceOneStep';
import ReferenceTwoStep from '../FormSteps/ReferenceTwoStep';
import IdentityStep from '../FormSteps/IdentityStep';
import { Typography, Box } from '@mui/material';
import ThankYouStep from '../FormSteps/ThankYouStep';
import { upsertTransaction } from '../../../services/transactionService.js';
import IntroStep from '../FormSteps/IntroStep'; // adjust the path as necessary
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { setSuccessOpen, setErrorOpen } from '../../../redux/snackbar/snackbarSlice.js';

const MultiStepForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.form); // Adjust the path according to your store setup
    const { currentStep } = useSelector((state) => state.form);
    const linkToken = useSelector((state) => state.form.linkToken);
    const successOpen = useSelector((state) => state.snackbar.successOpen);
    const errorOpen = useSelector((state) => state.snackbar.errorOpen);
    const successMessage = useSelector((state) => state.snackbar.successMessage);
    const errorMessage = useSelector((state) => state.snackbar.errorMessage);

    const totalSteps = 13; // Total number of steps    
    const progress = (formData.currentStep / (totalSteps - 1)) * 100;
    const stepNames = ["Welcome", "Name", "Credit/Debit Card", "Bank", "Contact", "Address", "Housing", "Identity", "Occupation", "Employer", "Reference One", "Reference Two"];

    useEffect(() => {
        // Assuming step URLs are like '/step-1', '/step-2', etc.
        const pathStepNumber = parseInt(window.location.pathname.split('-')[1]);
        if (pathStepNumber && pathStepNumber !== currentStep) {
            navigate(`/step-${currentStep}`);
        }
    }, [currentStep, navigate]);

    const handleStepChange = async () => {
        await upsertTransaction(formData.transactionId, formData.merchantId, formData.firstName, formData.lastName, formData.email, formData.phoneNumber);
        // Add any additional logic or code here
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        dispatch(setSuccessOpen(false));
        dispatch(setErrorOpen(false));
    };

    return (
        <div className="multi-step-form">

            {currentStep > 0 && currentStep < totalSteps && (
                <Box>
                    <Typography className="header-title">
                        Financing Application: {stepNames[formData.currentStep]}
                    </Typography>
                    <LinearProgressBar value={progress} />
                </Box>
            )}
            <Routes>
                <Route path="/" element={<IntroStep linkToken={linkToken} />} />
                <Route path="step-0" element={<IntroStep linkToken={linkToken} />} />
                <Route path="step-1" element={<NameStep handleStepChange={handleStepChange} />} />
                <Route path="step-2" element={<CreditCardStep />} />
                <Route path="step-3" element={<BankStep linkToken={linkToken} />} />
                <Route path="step-4" element={<ContactStep handleStepChange={handleStepChange} />} />
                <Route path="step-5" element={<AddressStep />} />
                <Route path="step-6" element={<HousingStep />} />
                <Route path="step-7" element={<IdentityStep />} />
                <Route path="step-8" element={<OccupationStep />} />
                <Route path="step-9" element={<EmployerStep />} />
                <Route path="step-10" element={<ReferenceOneStep />} />
                <Route path="step-11" element={<ReferenceTwoStep />} />
                <Route path="step-12" element={<ThankYouStep />} />
            </Routes>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="success" elevation={6} variant="filled">                    
                    {successMessage}
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="error" elevation={6} variant="filled">
                    {errorMessage}
                </MuiAlert>
            </Snackbar>

        </div>
    );
};

export default MultiStepForm;
